import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import RichText from '../components/rich-text'
import GlobalDuplexBanner from '../components/global-duplex-banner'
import '../styles/blog-post.css'
import ogMetaImage from '../../static/entratalogo_1200x630.png'
const contentful = require('contentful')

export default function BlogPage({ pageContext, data }) {
  const [ post, setPost ] = useState()

  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.CONTENTFUL_SPACE_ID,
      accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
    })
    const getPost = async (slug) => {
      if (!post) {
        await client.getEntries({
          content_type: 'newsOrBlogPost',
          limit: 1,
          include: 10,
          'fields.slug': slug
        })
        .then((entry) => setPost(entry))
        .catch(console.error)
      } else {
        null
      }
    }
    getPost(pageContext?.slug)
  }, [])

  const richText = {
    richTextData: pageContext?.bodyContent?.raw ? JSON.parse(pageContext.bodyContent.raw) : null,
    content: post?.items[0].fields.bodyContent ? post?.items[0].fields.bodyContent.content : '',
    assetSize: '75%' // CSS width property
  }
  const imgStyles = {
    backgroundImage: `url(${pageContext?.image[0]?.url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  return (
    <Layout header={pageContext?.header} footer={pageContext?.footer}>
      <div className="blog-post">
        <div className="content-cap">
          <div className="blog-post-content">
            <div className="blog-intro">
              <div className="title-holder">
                <h2>{pageContext.metaTitle}</h2>
              </div>
              <div className="image-holder" style={imgStyles}></div>
            </div>
            <div className="text-holder">
              <ReactMarkdown className="blog-text">{pageContext.bodyText}</ReactMarkdown>
              {
                pageContext?.bodyContent?.raw || pageContext?.bodyContent?.references ?
                  <div className='blog-rich-text'>
                    { post ? <RichText data={richText} /> : '' }
                  </div>
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
      <GlobalDuplexBanner layoutPiece={data.allContentfulDuplexFeature.nodes[0]}/>
    </Layout>
  )
}

export const Head = ({pageContext}) => (
  <>
    <meta name="description" content={pageContext.metaDescription} />
    <title>{pageContext?.metaTitle}</title>
    <meta property="og:title" content={pageContext?.ogMetaTitle ? pageContext.ogMetaTitle : pageContext?.metaTitle} />
    <meta property="og:description" content={pageContext?.ogMetaDescription ? pageContext.ogMetaDescription : pageContext?.metaDescription} />
    <meta property="og:type" content={pageContext?.ogMetaType} />
    <meta property="og:url" content={pageContext?.slug ? `https://www.entrata.com${pageContext?.slug}` : undefined} />
    <meta property="og:image" content={pageContext?.ogMetaImage?.image ? pageContext?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query BlogFooter {
    allContentfulDuplexFeature(filter: {contentful_id: {eq: "4KnefgsBcgsmngoRxMVOgk"}}) {
      nodes {
        ...DuplexFeature
      }
    }
  }
`